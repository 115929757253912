import React, { useContext, useState } from "react";
import {
  Button,
  Caption,
  Card,
  Collapsible,
  DisplayText,
  // Link,
  List,
  ProgressBar,
  SkeletonBodyText,
  Stack,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { StyledCardHeader } from "asset/styles/globalStyle";
import { StyledPlanCard, InnerCard } from "./planCardStyle";

import config from "configuration";

const OperatorPlanCard = () => {
  const { cms, currentUser, isLoading } = useContext(PrivateContext);
  const [openPlanCard, setOpenPlanCard] = useState(true);

  const isMaison = currentUser.shop === "maisonflaneur";

  const { plan = {}, products = {}, vendors = {} } = currentUser;

  const { code = "", name = "", price = "", isPAYE = false } = plan; // , isChargebee = false
  const { max: maxProduct = "", used = "" } = products;
  const { max: maxVendor = "", associated = "" } = vendors;
  const planURL = "/update/plan"; // isChargebee ? "/update/plan" : "/plan";

  if (isLoading) {
    return <SkeletonBodyText lines={3} />;
  }

  const isPayeData = !!(plan && plan.paye && plan.paye.calculatedPrice);

  const chargeCurrency = isMaison ? currentUser.moneyFormat : "$";
  const payeRate = isMaison ? "2.5" : "3";

  return (
    <Card>
      <StyledPlanCard>
        <StyledCardHeader>
          <Card.Header title={isPAYE ? "Your Plan" : cms("common.label.planCard.title")}>
            <Button
              plain
              disclosure={openPlanCard ? "up" : "down"}
              onClick={() => {
                setOpenPlanCard(!openPlanCard);
              }}
            >
              {openPlanCard ? cms("common.label.hide") : cms("common.label.show")}
            </Button>
          </Card.Header>
        </StyledCardHeader>
        <Collapsible
          open={openPlanCard}
          id="collapsiblePlanCard"
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <Card>
            <InnerCard className="inner-card">
              <Card.Section subdued>
                <div className="stack-wrapper plan">
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <TextStyle variation="strong">
                        {isPAYE ? "Pay as You Earn (PAYE)" : baseHelper.ucFirst(baseHelper.getPlanBadge(code || name))}
                      </TextStyle>
                      <Caption>
                        <TextStyle variation="subdued">{cms("common.label.planCard.currentPlan")}</TextStyle>

                        {/* <div>
                    <Link url={planURL}>
                      <TextStyle variation="strong">{cms("common.button.upgrade")}</TextStyle>
                    </Link>
                  </div> */}
                      </Caption>
                    </Stack.Item>
                    {isPAYE && isPayeData && (
                      <Stack.Item>
                        <List type="bullet">
                          <List.Item><b>Charge* (as calculated till today):</b> {chargeCurrency}{plan.paye.calculatedPrice}</List.Item>
                          <List.Item><b>Billing Term Start Date:</b> {baseHelper.formatDate(plan.paye.startTermDate)}</List.Item>
                          <List.Item><b>Billing Term End Date:</b> {baseHelper.formatDate(plan.paye.endTermDate)}</List.Item>
                        </List>
                        <br />
                        {/* <Card.Section title="Note*"> */}
                        <b>Note*</b>
                        <List type="bullet">
                          <List.Item>Calculated on the basis of {plan.paye.orderCount} orders between billing term.</List.Item>
                          <List.Item>Calculated as {payeRate}% of the total order value within the billing term.</List.Item>
                        </List>
                        {/* </Card.Section> */}
                      </Stack.Item>
                    )}
                    {!isPAYE && (
                      <Stack.Item>
                        <DisplayText size="small">{`$${price}/m`}</DisplayText>
                      </Stack.Item>
                    )}
                  </Stack>
                </div>
                {!isPAYE && [
                  <div className="stack-wrapper count">
                    <Stack>
                      <Stack.Item fill>
                        <TextStyle>{cms("common.label.tabs.operator.products")}</TextStyle>
                      </Stack.Item>
                      <Stack.Item>
                        <TextStyle variation="subdued">{`${used}/${maxProduct}`}</TextStyle>
                      </Stack.Item>
                    </Stack>
                    <ProgressBar progress={(used / maxProduct) * 100} size="small" />
                  </div>,
                  <div className="stack-wrapper count">
                    <Stack>
                      <Stack.Item fill>
                        <TextStyle>{cms("common.label.tabs.operator.providers")}</TextStyle>
                      </Stack.Item>
                      <Stack.Item>
                        <TextStyle variation="subdued">{`${associated}/${maxVendor}`}</TextStyle>
                      </Stack.Item>
                    </Stack>
                    <ProgressBar progress={(associated / maxVendor) * 100} size="small" />
                  </div>
                ]}
                <div className="upgrade-button">
                  <Button primary fullWidth url={currentUser.isTest ? config.stripePortal('uat') : config.stripePortal()}>
                    Manage your billing
                  </Button>
                </div>
                <br />
                <div className="upgrade-button">
                  <Tooltip content="Contact us to upgrade your plan">
                    <Button primary fullWidth>
                      {/* url={planURL} */}
                      {cms("common.button.upgrade")}
                    </Button>
                  </Tooltip>
                </div>
              </Card.Section>
            </InnerCard>
          </Card>
        </Collapsible>
      </StyledPlanCard>
    </Card>
  );
};

export default OperatorPlanCard;
