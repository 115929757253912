import React, { useContext } from "react";
import { Caption, ResourceList, Stack, TextStyle } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { ruleItemProp } from "app/shipping/modules/operator/features/rule/propTypes";

import Actions from "./action";
import { baseHelper } from "lib/helpers";

const RuleItem = (props) => {
  const {
    moneyFormat,
    item,
    bands = [],
    destinations,
    products,
    isVendorAllowed,
    toggleModal,
    toggleDeleteModal,
  } = props;
  const {
    _id: id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    isShippingDisabled,
    createdAt,
    updatedAt,
  } = item;

  const { cms } = useContext(PrivateContext);
  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;
  const shippingBand = (bands.length > 0 && bands.find((key) => key.value === shippingBandId)) || {};

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);
  const isFreeOption = priceType === constant.FREE;
  const priceTypeText = isFreeOption ? cms("label.free") : cms("label.fixed");
  const shortcutActions = [
    {
      content: cms("button.edit"),
      onAction: () => toggleModal(id),
      disabled: !isVendorAllowed,
    },
    {
      content: cms("button.delete"),
      destructive: true,
      onAction: () => toggleDeleteModal(id),
      disabled: !isVendorAllowed,
    },
  ];

  return (
    <ResourceList.Item id={id} accessibilityLabel={`${cms("label.viewDetails")} ${shippingBand.label}`}>
      <Stack alignment="leading" wrap={false}>
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{shippingBand.label}</TextStyle>
          </h3>
          <Caption>{description}</Caption>
          <br />
          {isDestination && <Caption>{`${cms("label.destination")}: ${destination && destination.label}`}</Caption>}
          {isProduct && <Caption>{`${cms("label.product")}: ${product && product.label}`}</Caption>}
          <Caption>{`${cms("label.priceType")}: ${priceTypeText}`}</Caption>
          <Caption>{`${cms("label.cost")}: ${moneyFormat} ${isFreeOption ? "0" : price}`}</Caption>
          <Caption>
            {`${cms("label.shipping")}: ${isShippingDisabled ? cms("label.disabled") : cms("label.enabled")}`}
          </Caption>
          {createdAt && (
            <Caption>
              {`Created At: ${baseHelper.formatDate(createdAt)}`}
            </Caption>
          )}
          {updatedAt && (
            <Caption>
              {`Last updated At: ${baseHelper.formatDate(updatedAt)}`}
            </Caption>
          )}
        </Stack.Item>
        <Actions actions={shortcutActions} />
      </Stack>
    </ResourceList.Item>
  );
};

RuleItem.propTypes = ruleItemProp.type;

export default RuleItem;
