import React, { useState, useEffect, useCallback } from "react";

import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import Talk from "talkjs";
import Intercom, { shutdown as IntercomShutdown } from '@intercom/messenger-js-sdk';

import config from "configuration";
import { PrivateLayout } from "layout";
import privateRouteCodes from "routes/private/routeCode/routeCode";

import { storageHelper, baseHelper, accessHelper, featureHelper, hooksHelper, chatHelper } from "lib/helpers";

import constant from "lib/constant/constant";
import { NotFound, NotAllowed, Suspended } from "layout/private/components";

// import context
import privatePageContent from "routes/private/pageContent/pageContent";
import { PrivateProvider } from "lib/context";
import GET_ASSOCIATED_SELLER from "app/userManagement/apollo/queries/getAssociatedSeller";
import { FETCH_VENDOR_ACCESS } from "app/advanceVendor/apollo/queries";
import PrivateBrandingContainer from "./privateBrandingContainer";

// import queries
import { GET_OPERATOR_SETTING, GET_USER_CONTENT, GET_SELLER_BRANDING } from "./apollo/queries";

const { shopifyAppID } = config;
let popup = {};
let defaultConversation = {};

const PrivateContainer = (props) => {
  const { EMAIL_NOT_PROVIDE, EVENTS, featureError, gql, userKey } = constant;
  const { operator, provider } = userKey;

  const [updatedPageData, setUpdatedPageData] = useState(null);
  const [onboardingTab, setOnboardingTab] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isTimeOut, setIsTimeout] = useState(false);
  const [talkCurrentUser, setTalkCurrentUser] = useState(false);

  const [shop, setShop] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");

  const [chatSetting, setSetting] = useState({
    isCustomer: true,
    isVendor: true,
  });

  const { component: Component, ...rest } = props;
  const { match, history } = rest;
  const { path } = match;

  const productSearch = storageHelper.get("productSearch");
  if (path === "/products" && productSearch && history.location.search !== productSearch) {
    history.push({
      pathname: path,
      search: productSearch,
    });
  }

  const {
    code = "",
    cms = "",
    isTwoColumnLayout = false,
    parentModule = "dashboard",
    module = "",
    pageInfo,
    rolesAllowed = [],
    isPrivateLayoutHidden = false,
    isTabsHidden = false,
    dependentKey = [],
    isFullWidth = false,
  } = privateRouteCodes[path];

  const pageData = privatePageContent[code || pageInfo || module] || {};

  const {
    loading: privateContainerLoading,
    // error: privateContainerError,
    data: privateContainerData,
    networkStatus: privateContainerNetworkStatus,
    refetch: currentUserRefetch,
  } = useQuery(GET_USER_CONTENT, {
    variables: {
      getContentInput: {
        routeCode: cms,
      },
      getFeatureInput: {
        routeCode: module,
        dependentKey,
      },
      getPublicCMSInput: {
        language: "en",
        code: (isOnboarding && isOnboarding.cms) || cms,
        module: (isOnboarding && isOnboarding.module) || parentModule,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: operatorSettingData, loading: loadingOperatorSetting = false } = useQuery(GET_OPERATOR_SETTING);

  const { loading: accessLoading = false, data: vendorAccessResponse } = useQuery(FETCH_VENDOR_ACCESS);

  const isLoading =
    privateContainerNetworkStatus === 4 || privateContainerLoading || accessLoading || loadingOperatorSetting;

  const sellerSetting = baseHelper.getResponseData(operatorSettingData, gql.GET_OPERATOR_SETTING);

  const vendorAccessData = baseHelper.getResponseData(vendorAccessResponse, gql.GET_VENDOR_ACCESS_SETTING) || {};
  const cmsData = baseHelper.getResponseData(privateContainerData, gql.GET_CONTENT);
  const newCMSData = baseHelper.getResponseData(privateContainerData, "getPublicCMS");
  const currentUserData = baseHelper.getResponseData(privateContainerData, gql.GET_CURRENT_USER);
  const featureFlagData = baseHelper.getResponseData(privateContainerData, gql.GET_FEATURE);
  const { data: sellerBranding } = useQuery(GET_SELLER_BRANDING);
  const sellerBrandingData = baseHelper.getResponseData(sellerBranding, gql.GET_SELLER_BRANDING);
  const { brandName: vendorName = {} } = currentUserData || {};

  const isRootDataFetchedSuccessfully = !!currentUserData;
  const { useCMS } = hooksHelper;
  const CMS = useCMS(newCMSData);

  const { isInactive, isReadOnly, requestedBy } = currentUserData;

  const isSeller = baseHelper.isSeller(currentUserData);
  const isBigCommerceSeller = baseHelper.isBigCommerceUser(currentUserData);
  const isVendor = baseHelper.isVendor(currentUserData);
  const checkIfCustomOnboard = !!(isSeller && currentUserData && currentUserData.setupProgress === "welcome");

  const isShopifyCustomOperator = !!(
    isSeller &&
    currentUserData &&
    currentUserData.ecommercePlatform !== "bigCommerce" &&
    currentUserData.storeConfig &&
    currentUserData.storeConfig.shopify &&
    currentUserData.storeConfig.shopify.apiKey &&
    currentUserData.storeConfig.shopify.apiSecretKey
  );
  const isShopConnected = !!(
    isVendor &&
    currentUserData.isOrderConnect &&
    currentUserData.shop &&
    currentUserData.shopifyAccessToken
  );

  // const isShopifyOldVendor = !!(isShopConnected && (!currentUserData.appId || currentUserData.appId === shopifyAppID));
  const isShopifyOldVendor = false;
  const { loading: isChargeBeeLoading = false, data: associatedSellerData } = useQuery(GET_ASSOCIATED_SELLER);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const associatedSeller = baseHelper.getResponseData(associatedSellerData, gql.GET_ASSOCIATED_SELLER) || {};
  const { setting } = associatedSeller || {};
  const isChargeBeeSeller = !!(associatedSeller && associatedSeller.isChargebee && associatedSeller.chargebee);
  const isStripeSeller = !!(
    associatedSeller &&
    associatedSeller.isStripeSubscription &&
    associatedSeller.stripeSubscription
  );

  useEffect(() => {
    if (sellerSetting) {
      const { chat = {} } = sellerSetting || {};
      if (chat) {
        setSetting({
          isCustomer: chat.isCustomer,
          isVendor: chat.isVendor,
        });
      }
    }
  }, [sellerSetting]);

  useEffect(() => {
    if (sellerBrandingData) {
      const { shop: shopName = "" } = sellerBrandingData || {};
      setShop(shopName);
    }
  }, [sellerBrandingData]);

  useEffect(() => {
    const siteconfig = isBigCommerceSeller ? config.chargeBeeSiteBigCommerece : config.chargeBeeSite;
    if (isSeller) {
      window.Chargebee.init({
        site: siteconfig,
        isItemsModel: true,
      });
    }

    if (associatedSeller) {
      const { email = {}, isChargebee = false, chargebee = {} } = associatedSeller || {};

      const { address = "" } = email || {};
      setSellerEmail(address.toString());
      if (isChargebee) {
        window.Chargebee.init({
          site: chargebee.site,
          isItemsModel: true,
        });
      }
    }
  }, [associatedSeller]);

  useEffect(() => {
    const logout = () => {
      if (!isReadOnly && requestedBy) {
        setIsTimeout(true);
      }
    };

    if (isInactive) {
      storageHelper.remove("token");
      storageHelper.remove("userRole");
      storageHelper.remove("isInitial");
      storageHelper.remove("setupProgress");
      storageHelper.removeAllCookie();
      history.push({ pathname: "/login", state: { isUserNotFound: true } });
    }

    const resetTimeout = () => {
      setTimeout(() => {
        logout();
      }, 1800 * 1000);
    };
    for (const i in EVENTS) {
      window.addEventListener(EVENTS[i], resetTimeout);
    }
  });

  useEffect(() => {
    const { isVendor: isChatVendor = true } = chatSetting || {};
    const { ecommercePlatform } = currentUserData;
    if (
      isChatVendor &&
      isVendor &&
      sellerEmail &&
      currentUserData &&
      !ecommercePlatform &&
      !window.talkSession &&
      !currentUserData.setupProgress
    ) {
      Talk.ready.then(() => {
        const custom = {
          email: currentUserData.email.address || EMAIL_NOT_PROVIDE,
        };

        const me = chatHelper.getTalkJSUser(
          currentUserData._id,
          `${currentUserData.firstName} ${currentUserData.lastName}`,
          currentUserData.email.address,
          currentUserData.roles.name,
          currentUserData.logo,
          custom
        );

        chatHelper.createTalkJSSession(me);

        const other = chatHelper.getTalkJSUser(
          currentUserData.introductorySeller,
          currentUserData.introductorySellerName,
          sellerEmail,
          operator
        );
        const conversation = chatHelper.createTalkJSConversion(me, other);
        popup = chatHelper.createTalkJSPopup(conversation);
        defaultConversation = conversation;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData, isVendor, sellerEmail]);

  useEffect(() => {
    if (isVendor && currentUserData && sellerEmail && !talkCurrentUser) {
      Talk.ready.then(() => {
        const me = chatHelper.getTalkJSUser(
          currentUserData._id,
          `${currentUserData.firstName} ${currentUserData.lastName}`,
          currentUserData.email.address,
          currentUserData.roles.name,
          currentUserData.logo
        );

        chatHelper.createTalkJSSession(me);
        setTalkCurrentUser(me);
      });
    }
    if (isVendor && currentUserData && currentUserData.introductorySeller === "63c10eb2b93d39001af0ff6c") {
      Intercom({
        app_id: 'c7tdyplk',
        alignment: 'right',
        user_id: currentUserData._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: `${currentUserData.firstName || ""} ${currentUserData.lastName || ""}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: currentUserData.email && currentUserData.email.address, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: currentUserData.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      })
      if (window.Beacon) {
        window.Beacon('destroy')
      }
    } else {
      IntercomShutdown();
      if (config.beaconLiveChatAPIKey && window.Beacon && currentUserData) {
        window.Beacon("identify", {
          name: `${currentUserData.firstName || ""} ${currentUserData.lastName || ""}`,
          email: currentUserData.email && currentUserData.email.address,
          company: currentUserData.brandName,
          jobTitle: currentUserData.roles && currentUserData.roles.name,
        });
      }
    }
  }, [currentUserData, isVendor, talkCurrentUser, sellerEmail]);

  if ((!isLoading && !isRootDataFetchedSuccessfully) || isTimeOut) {
    storageHelper.remove("token");
    storageHelper.remove("userRole");
    storageHelper.remove("isInitial");
    storageHelper.remove("setupProgress");
    storageHelper.removeAllCookie();
    history.push({ pathname: "/", state: { isUserNotFound: true } });
  }
  let isNotAllowed = false;
  let isNotFound = false;
  if (featureFlagData.key && currentUserData) {
    const checkPermission = featureHelper.checkPermission(currentUserData, featureFlagData);
    if (checkPermission === featureError.roleNotAllowed) {
      isNotAllowed = true;
    }
    if (checkPermission === featureError.hideFeature) {
      isNotFound = true;
    }
  }

  const setPageData = useCallback(
    (data) => {
      // NOTE: de-structure more data, as per your need
      const { title = "", primaryAction = null, subtitle = "" } = data;
      setUpdatedPageData({ ...pageData, title: pageData.title || title, primaryAction, subtitle });
    },
    [pageData]
  );

  let formattedCMSData = {};
  if (isRootDataFetchedSuccessfully) {
    formattedCMSData = baseHelper.getFormattedCMS(cmsData);
    const currentUserRole = accessHelper.getUserRole(currentUserData);

    // NOTE: Need to improve this logic
    if (currentUserData && currentUserData.setupProgress !== null) {
      storageHelper.set("setupProgress", currentUserData.setupProgress);
    } else {
      storageHelper.remove("setupProgress");
    }
    storageHelper.set("userRole", currentUserRole);
    storageHelper.set("lang", storageHelper.get("lang") || "en");
    storageHelper.set("isInitial", false);

    const userDetails = baseHelper.getChatUser(currentUserData);

    if (isSeller) {
      if (!talkCurrentUser && currentUserData) {
        Talk.ready.then(() => {
          const { brand = {} } = currentUserData || {};
          const { favicon = {} } = brand || {};
          const { imageUrl = "" } = favicon || {};

          const me = chatHelper.getTalkJSUser(
            currentUserData._id,
            `${currentUserData.firstName} ${currentUserData.lastName}`,
            currentUserData.email.address,
            currentUserData.roles.name,
            imageUrl
          );

          chatHelper.createTalkJSSession(me);
          setTalkCurrentUser(me);
        });
      }
      userDetails.shop = currentUserData.shop;
    }

    chatHelper.setUserDetails(userDetails);
  }

  const vendorHiddenModule = baseHelper.getHiddenModule(vendorAccessData, currentUserData);
  const vendorReadOnlyModule = baseHelper.getReadOnlyModule(vendorAccessData, currentUserData);
  const hiddenModuleKeys = baseHelper.getModuleKeys(vendorHiddenModule) || [];

  // check if the vendor is allowed the access the route/page when its hide access from seller
  // check if the user is allowed the access the route/page
  // also works for identifying invalid private routes
  const isUserAllowed = rolesAllowed.find((role) => role === storageHelper.get("userRole"));

  const isNotVendorAllowed = isVendor && hiddenModuleKeys.includes(parentModule);

  if ((!isUserAllowed && storageHelper.get("userRole")) || isNotVendorAllowed) {
    return (
      <PrivateProvider
        value={{
          ...rest,
        }}
      >
        {isNotVendorAllowed ? <NotAllowed /> : <NotFound />}
      </PrivateProvider>
    );
  }

  if (currentUserData && currentUserData.isSuspended) {
    return (
      <PrivateProvider
        value={{
          ...rest,
        }}
      >
        <Suspended isVendor={isVendor} />
      </PrivateProvider>
    );
  }

  const FullLayout = () => (
    <PrivateBrandingContainer>
      <PrivateLayout
        setIsOnboarding={setIsOnboarding}
        onboardingTab={onboardingTab}
        isChargeBeeSeller={isChargeBeeSeller}
      >
        <Component />
      </PrivateLayout>
    </PrivateBrandingContainer>
  );

  const initiateChat = (user) => {
    if (!talkCurrentUser) {
      return false;
    }

    const { _id: userDataId, firstName = "", email: userEmail = "", role = "", profileImage } = user || {};

    let other = {};
    if (shop) {
      const custom = {
        shop,
        vendorShop: vendorName,
        emailAdd: currentUserData.email.address || EMAIL_NOT_PROVIDE,
      };
      other = chatHelper.getTalkJSUser(userDataId, firstName, userEmail, role || provider, profileImage, custom);
    } else {
      other = chatHelper.getTalkJSUser(userDataId, firstName, userEmail, role || provider, profileImage);
    }

    const conversation = chatHelper.createTalkJSConversion(talkCurrentUser, other);

    const currentConversation = conversation;
    if (popup && Object.keys(popup).length !== 0) {
      popup.show();
      popup.select(conversation);
      // eslint-disable-next-line func-names
      popup.on("close", function () {
        // eslint-disable-next-line no-unused-expressions
        defaultConversation && Object.keys(defaultConversation).length !== 0
          ? popup.select(defaultConversation)
          : popup.select(currentConversation);
        popup.off();
      });
    } else {
      popup = chatHelper.createTalkJSPopup(conversation);
      popup.show();
    }
    return false;
  };

  const initiateQuoteChat = (customer, vendor, seller, subject = "Quote Discussion") => {
    if (!talkCurrentUser) {
      return false;
    }

    const { _id: userDataId, firstName = "", email: userEmail = "", role = "" } = customer || {};

    const customerData = chatHelper.getTalkJSUser(userDataId, `${firstName}`, userEmail, role);

    const vendorData = chatHelper.getTalkJSUser(
      vendor.id,
      `${vendor.firstName} ${vendor.lastName}`,
      vendor.email,
      vendor.role
    );

    const sellerData = chatHelper.getTalkJSUser(
      seller.id,
      `${seller.firstName} ${seller.lastName}`,
      seller.email,
      seller.role
    );

    const conversation = chatHelper.createTalkJSGroupConversion({
      vendor: vendorData,
      customer: customerData,
      subject,
      seller: sellerData,
    });

    const currentConversation = conversation;
    if (popup && Object.keys(popup).length !== 0) {
      popup.show();
      popup.select(conversation);
      // eslint-disable-next-line func-names
      popup.on("close", function () {
        // eslint-disable-next-line no-unused-expressions
        defaultConversation && Object.keys(defaultConversation).length !== 0
          ? popup.select(defaultConversation)
          : popup.select(currentConversation);
        popup.off();
      });
    } else {
      popup = chatHelper.createTalkJSPopup(conversation);
      popup.show();
    }
    return false;
  };

  if (window.talkSession && window.talkSession.unreads) {
    try {
      window.talkSession.unreads.on("change", function (unreadConversations) {
        setUnreadMessageCount(unreadConversations.length);
      });
    } catch (talkJsErr) {
      // console.log("Talk JS Error--", talkJsErr);
    }
  }

  const destroyPopup = () => popup && Object.keys(popup).length !== 0 && popup.destroy();

  if (currentUserData.isProductDiscovery) {
    currentUserData.fulfillmentScopeExists = true;
  }

  const LayoutComponent = () => <Component />;
  return (
    <PrivateProvider
      value={{
        associatedSeller,
        cms: CMS,
        oldCMS: formattedCMSData,
        chatSetting,
        currentUser: currentUserData,
        currentUserRefetch,
        isLoading,
        isNotAllowed,
        isNotFound,
        isTwoColumnLayout,
        isTabsHidden,
        isFullWidth,
        isStripeSeller,
        loadingOperatorSetting,
        operatorSettingData,
        featureFlag: featureFlagData,
        pageData: updatedPageData || pageData,
        shop, // Added shop name for vendor end as not available in current user,
        setSetting,
        setPageData,
        destroyPopup,
        initiateChat,
        initiateQuoteChat,
        talkCurrentUser,
        setOnboardingTab,
        setIsOnboarding,
        unreadMessageCount,
        userData: privateContainerData,
        // vendorAccess: vendorAccessData,
        vendorHiddenModule,
        vendorHiddenModuleKeys: hiddenModuleKeys,
        vendorReadOnlyModule,
        isChargeBeeSeller,
        isChargeBeeLoading,
        setting,
        isShopifyCustomOperator,
        isShopConnected,
        isShopifyOldVendor,
        checkIfCustomOnboard,
        ...rest,
      }}
    >
      {isPrivateLayoutHidden ? LayoutComponent() : FullLayout()}
    </PrivateProvider>
  );
};

PrivateContainer.propTypes = {
  component: PropTypes.node,
};

PrivateContainer.defaultProps = {
  component: null,
};

export default PrivateContainer;
