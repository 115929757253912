import { gql } from "apollo-boost";

const GET_SHIPPING_BAND = gql`
  query getShippingBand($input: GetListInputV1) {
    getShippingBand(input: $input) {
      data {
        shippingRows {
          _id
          description
          isUpdateAllowed
          name
          parentId
          price
          priceType
          userId
          createdAt
          updatedAt
        }
        shippingTerm
      }
      status
      error
    }
  }
`;

const GET_SHIPPING = gql`
  query {
    getShipping {
      data {
        shipping {
          _id
          sellerId
          isHideDescription
          term
          type
          amount
          vendorIds
          allowVendor
          isFreeForCustomer
          shippingPerVendor {
            vendorIds
            isAllowed
          }
          setting {
            isAllBand
            isAllRule
            isAllOffer
            isAllVendor
            bandIds
            ruleIds
            offerIds
            vendorIds
          }
          createdAt
          updatedAt
          isShippingByVendor
          shippingByVendor {
            all {
              amount
              type
            }
            selected {
              type
              amount
              vendorId
            }
          }
          shippingByCurrency {
            amount
            type
            currencyCode
            destination
            taxRate
            vendorId
          }
        }
        vendors {
          label
          value
        }
        isShippingBandAvailable
      }
      status
      error
    }
  }
`;

const GET_LAKE_SHIPPING = gql`
  query {
    getLakeShipping {
      data {
        lakeShipping {
          _id
          sellerId
          isHideDescription
          term
          type
          amount
          vendorIds
          allowVendor
          isFreeForCustomer
          shippingPerVendor {
            vendorIds
            isAllowed
          }
          setting {
            isAllBand
            isAllRule
            isAllOffer
            isAllVendor
            bandIds
            ruleIds
            offerIds
            vendorIds
          }
          createdAt
          updatedAt
          isShippingByVendor
          shippingByVendor {
            all {
              amount
              type
            }
            selected {
              type
              amount
              vendorId
            }
          }
        }
        vendors {
          label
          value
        }
        isShippingBandAvailable
      }
      status
      error
    }
  }
`;

export { GET_SHIPPING, GET_LAKE_SHIPPING, GET_SHIPPING_BAND };
