import React, { useContext } from "react";
import { Caption, ResourceItem, Stack, TextStyle } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "app/shipping/modules/provider/features/subFeatures/advance/context/context";

// import propType
import { offerItemProp } from "app/shipping/modules/provider/features/subFeatures/advance/offers/propsType";

// import component
import Actions from "./action";

const OfferItem = (props) => {
  const { isVendorAllowed } = useContext(AdvanceShippingContext);
  const { currentUser = {}, cms } = useContext(PrivateContext);
  const { moneyFormat, item, destinations = [], products = [], bands = [], toggleModal, toggleDeleteModal } =
    props || {};
  const { _id: currentUserId = "" } = currentUser || {};
  const {
    _id,
    shippingBandId,
    description,
    condition,
    destinationCode,
    productQuantity = {},
    productPrice = {},
    orderValue = {},
    productId,
    priceType,
    price,
    isCumulative = false,
    createdBy,
    createdAt,
    updatedAt,
  } = item || {};
  const { FIXED, FREE, ORDER_VALUE, PRODUCT_PRICE, PRODUCT_QUANTITY, GREATER_THAN } = constant;
  const isProductPrice = condition === PRODUCT_PRICE;
  const isProductQuantity = condition === PRODUCT_QUANTITY;
  const isOrderValue = condition === ORDER_VALUE;
  const isCreatedBySeller = baseHelper.mongoIdAsString(createdBy) !== baseHelper.mongoIdAsString(currentUserId);

  const shippingBand =
    (bands.length > 0 &&
      bands.find((band) => {
        return band.value === shippingBandId || band.parentId === shippingBandId;
      })) ||
    "";
  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);
  const isFreeOption = priceType === FREE;
  const priceTypeText = isFreeOption ? FREE : FIXED;
  const shortcutActions = [
    {
      content: cms("button.edit"),
      onAction: () => toggleModal(item),
      disabled: !isVendorAllowed,
    },
    {
      content: cms("button.delete"),
      destructive: true,
      onAction: () => toggleDeleteModal(_id, shippingBandId),
      disabled: isCreatedBySeller || !isVendorAllowed,
    },
  ];
  return (
    <ResourceItem id={_id}>
      <Stack alignment="leading" wrap={false}>
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{(shippingBand && shippingBand.label) || ""}</TextStyle>
          </h3>

          <Caption>{description}</Caption>

          <br />
          <Caption>{` ${cms("label.destination")}: ${destination ? destination.label : cms("label.all")}`}</Caption>
          <Caption>{` ${cms("label.products")}: ${product ? product.label : cms("label.all")}`}</Caption>
          {isProductPrice && (
            <Caption>
              {` ${cms("label.productPrice")}:
              ${productPrice.criteria === GREATER_THAN ? cms("label.greaterThan") : cms("label.LessThan")}
               ${moneyFormat} ${productPrice.value}`}
            </Caption>
          )}
          {isProductQuantity && (
            <Caption>
              {`  ${cms("label.productQuantity")}:
              ${productQuantity.criteria === GREATER_THAN ? cms("label.greaterThan") : cms("label.LessThan")}
             ${productQuantity.value}`}
            </Caption>
          )}
          {isOrderValue && (
            <Caption>
              {` ${cms("label.orderValue")}:
              ${orderValue.criteria === GREATER_THAN ? cms("label.greaterThan") : cms("label.LessThan")}
              ${` ${moneyFormat} ${orderValue.value}`}`}
            </Caption>
          )}
          <Caption>{` ${cms("label.priceType")}: ${priceTypeText}`}</Caption>
          <Caption>{` ${cms("label.cost")}: ${moneyFormat} ${isFreeOption ? "0" : price}`}</Caption>
          {!isOrderValue && (
            <Caption>
              {` ${cms("label.chargeCumulative")}: ${isCumulative ? cms("label.yes") : cms("label.no")}`}
            </Caption>
          )}
          {createdAt && (
            <Caption>
              {`Created At: ${baseHelper.formatDate(createdAt)}`}
            </Caption>
          )}
          {updatedAt && (
            <Caption>
              {`Last updated At: ${baseHelper.formatDate(updatedAt)}`}
            </Caption>
          )}
        </Stack.Item>
        <Actions actions={shortcutActions} />
      </Stack>
    </ResourceItem>
  );
};

OfferItem.propTypes = offerItemProp.type;

export default OfferItem;
