import { gql } from "apollo-boost";

const GET_SHIPPING_RULE = gql`
  query {
    getShippingRules {
      data {
        shippingRules {
          _id
          condition
          createdBy
          description
          destinationCode
          isShippingDisabled
          parentId
          price
          priceType
          productId
          shippingBandId
          updatedBy
          userId
          createdAt
          updatedAt
        }
      }
      status
      error
    }
  }
`;

const GET_SHIPPING_RULE_REGION = gql`
  query getRegion {
    getRegion {
      data {
        options {
          value
          label
        }
        title
      }
      status
      error
    }
  }
`;

const GET_SHIPPING_RULE_PRODUCT = gql`
  query getProducts {
    getProducts {
      data {
        value
        label
      }
      status
      error
    }
  }
`;

export { GET_SHIPPING_RULE, GET_SHIPPING_RULE_REGION, GET_SHIPPING_RULE_PRODUCT };
