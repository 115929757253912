import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

class OrderHelper {
  /**
   * @desc To return orders csv data
   * @param orderLines array of orderlines
   *
   * @return csv data
   *
   */
  orderCSVData = (
    orderLines = [],
    settingResponse,
    isWholesalePriceVisible = false,
    isCsv = false,
    isPdf = false,
    isVendor = false,
    cms,
    sellerDefaultAddress = {}
  ) => {
    const settingData = settingResponse.export;
    const { isExcludeShippingAmount = false } = settingResponse || {};

    const {
      createdAt,
      fulfillmentStatus,
      lineItemId,
      markupPrice,
      orderId,
      orderNumber,
      orderStatus,
      price,
      product,
      quantity,
      shippingAddress,
      shippingCity,
      shippingCountry,
      shippingName,
      shippingPincode,
      shippingState,
      variantSku,
      vendor,
      variantWeight,
      wholesalePrice,
      totalDiscount,
      trackingNumber,
      discount,
      shipping,
      totalVendorPayout,
      tax,
      customerPrice,
      comparePrice,
    } = settingData || {};

    const tempOrderObjData = { price: (price && price.label) || cms("section.exportOrder.label.price") };

    if (isWholesalePriceVisible) {
      tempOrderObjData.wholesalePrice =
        (wholesalePrice && wholesalePrice.label) || cms("section.exportOrder.label.wholesaleprice");
    }

    const orderdata = {
      orderId: (orderId && orderId.label) || cms("section.exportOrder.label.orderid"),
      lineItemId: (lineItemId && lineItemId.label) || cms("section.exportOrder.label.lineitemid"),
      product: (product && product.label) || cms("section.exportOrder.label.product"),
      variantSku: (variantSku && variantSku.label) || cms("section.exportOrder.label.variantSku"),
      variantWeight: `${
        (variantWeight && variantWeight.label) || cms("section.exportOrder.label.variantWeight")
      } (in grams)`,
      orderNumber: (orderNumber && orderNumber.label) || cms("section.exportOrder.label.ordernumber"),
      orderStatus: (orderStatus && orderStatus.label) || cms("section.exportOrder.label.orderstatus"),
      fulfillmentStatus:
        (fulfillmentStatus && fulfillmentStatus.label) || cms("section.exportOrder.label.fulfillmentstatus"),
      // vendor: (vendor && vendor.label) || "Vendor",
      quantity: (quantity && quantity.label) || cms("section.exportOrder.label.quantity"),
      ...tempOrderObjData,
      // price: (price && price.label) || "Price",
      // markupPrice: (markupPrice && markupPrice.label) || "Markup Price",
      createdAt: (createdAt && createdAt.label) || cms("section.exportOrder.label.createdat"),
      trackingNumber: (trackingNumber && trackingNumber.label) || cms("section.orderList.label.trackingNumber"),
      shippingName: (shippingName && shippingName.label) || cms("section.exportOrder.label.shippingName"),
      shippingAddress: (shippingAddress && shippingAddress.label) || cms("section.exportOrder.label.shippingAddress"),
      shippingCity: (shippingCity && shippingCity.label) || cms("section.exportOrder.label.shippingCity"),
      shippingState: (shippingState && shippingState.label) || cms("section.exportOrder.label.shippingState"),
      shippingCountry: (shippingCountry && shippingCountry.label) || cms("section.exportOrder.label.shippingCountry"),
      shippingPincode: (shippingPincode && shippingPincode.label) || cms("section.exportOrder.label.shippingPincode"),
    };
    if (!isVendor) {
      orderdata.vendor = (vendor && vendor.label) || cms("section.exportOrder.label.vendor");
      orderdata.markupPrice = (markupPrice && markupPrice.label) || cms("section.exportOrder.label.markupprice");
    }
    if (isVendor) {
      orderdata.discount = (discount && discount.label) || cms("section.exportOrder.label.discount");
      orderdata.shipping = (shipping && shipping.label) || cms("section.exportOrder.label.shipping");
      orderdata.totalVendorPayout =
        (totalVendorPayout && totalVendorPayout.label) || cms("section.exportOrder.label.totatVendorPayout");
      orderdata.tax = (tax && tax.label) || cms("section.exportOrder.label.tax");
      orderdata.markupPrice = (markupPrice && markupPrice.label) || cms("section.exportOrder.label.markupprice");
      orderdata.comparePrice = (comparePrice && comparePrice.label) || cms("section.exportOrder.label.comparePrice");
      orderdata.customerPrice =
        (customerPrice && customerPrice.label) || cms("section.exportOrder.label.customerPrice");
    }
    const updatedArray = [];
    if (settingData) {
      const notAllowedCsvKeys = Object.keys(settingData).filter((item) => {
        let hideArray;
        if (isCsv) {
          hideArray = settingData[item] && settingData[item].isHideCsv;
        }

        if (isPdf) {
          hideArray = settingData[item] && settingData[item].isHidePdf;
        }
        return hideArray;
      });

      notAllowedCsvKeys.map((key) => {
        updatedArray.push(orderdata[key]);
        return false;
      });
    }
    const tempData = [(price && price.label) || cms("section.exportOrder.label.price")];

    if (isWholesalePriceVisible) {
      tempData.push((wholesalePrice && wholesalePrice.label) || cms("section.exportOrder.label.wholesaleprice"));
    }

    const orderArray = [
      (orderId && orderId.label) || cms("section.exportOrder.label.orderid"),
      (lineItemId && lineItemId.label) || cms("section.exportOrder.label.lineitemid"),
      (product && product.label) || cms("section.exportOrder.label.product"),
      (variantSku && variantSku.label) || cms("section.exportOrder.label.variantSku"),
      `${(variantWeight && variantWeight.label) || cms("section.exportOrder.label.variantWeight")} (in grams)`,
      (orderNumber && orderNumber.label) || cms("section.exportOrder.label.ordernumber"),
      (orderStatus && orderStatus.label) || cms("section.exportOrder.label.orderstatus"),
      (fulfillmentStatus && fulfillmentStatus.label) || cms("section.exportOrder.label.fulfillmentstatus"),
      // (vendor && vendor.label) || "Vendor",
      (quantity && quantity.label) || cms("section.exportOrder.label.quantity"),
      // (price && price.label) || "Price",
      ...tempData,
      // (markupPrice && markupPrice.label) || "Markup Price",
      (createdAt && createdAt.label) || cms("section.exportOrder.label.createdat"),
      (trackingNumber && trackingNumber.label) || cms("section.orderList.label.trackingNumber"),
      (shippingName && shippingName.label) || cms("section.exportOrder.label.shippingName"),
      (shippingAddress && shippingAddress.label) || cms("section.exportOrder.label.shippingAddress"),
      (shippingCity && shippingCity.label) || cms("section.exportOrder.label.shippingCity"),
      (shippingState && shippingState.label) || cms("section.exportOrder.label.shippingState"),
      (shippingCountry && shippingCountry.label) || cms("section.exportOrder.label.shippingCountry"),
      (shippingPincode && shippingPincode.label) || cms("section.exportOrder.label.shippingPincode"),
    ];

    if (!isVendor) {
      // orderArray.pop();
      orderArray.push((vendor && vendor.label) || cms("section.exportOrder.label.vendor"));
      orderArray.push((markupPrice && markupPrice.label) || cms("section.exportOrder.label.markupprice"));
    }
    if (isVendor) {
      orderArray.push((discount && discount.label) || cms("section.exportOrder.label.discount"));
      orderArray.push((shipping && shipping.label) || cms("section.exportOrder.label.shipping"));
      orderArray.push(
        (totalVendorPayout && totalVendorPayout.label) || cms("section.exportOrder.label.totalVendorPayout")
      );
      orderArray.push((tax && tax.label) || cms("section.exportOrder.label.tax"));
      orderArray.push((markupPrice && markupPrice.label) || cms("section.exportOrder.label.markupprice"));
      orderArray.push((comparePrice && comparePrice.label) || cms("section.exportOrder.label.comparePrice"));
      orderArray.push((customerPrice && customerPrice.label) || cms("section.exportOrder.label.customerPrice"));
    }

    const removeIndex = [];

    updatedArray.map((arr) => {
      const index = orderArray.indexOf(arr);
      removeIndex.push(index);
      return false;
    });

    const differenceArray = orderArray.filter((item) => !updatedArray.includes(item));

    const newOrderArray = [];
    newOrderArray[0] = differenceArray;

    orderLines.forEach((item, idx) => {
      const { shippingAddress: shippingData = {}, fulfillmentType = "", isOrderBasedCommission } = item || {};
      const {
        address: sellerAddress = "",
        city: sellerCity = "",
        country: sellerCountry = "",
        postalCode: sellerPostalCode = "",
      } = sellerDefaultAddress || {};
      let totalVendorPayout = 0;
      let totalPriceTax = 0;
      let totalAllocatedDiscount = 0;
      let customerPrice = 0;
      let price = 0;
      const { discountAllocations = [] } = item;
      totalAllocatedDiscount = discountAllocations.length ? discountAllocations[0].amount : 0;
      let totalShippingPrice = 0;
      // const shopifyPrice = item.priceSet && item.priceSet.shopMoney && item.priceSet.shopMoney.amount;
      price = item.price;
      customerPrice = price * item.quantity;
      const moneyFormat = item.moneyFormat === constant.symbol.RUPEE ? constant.symbol.RUPEES : item.moneyFormat;
      const tempOrderData = [`${moneyFormat} ${baseHelper.formatPrice(item.price * item.quantity || 0)}`];
      totalPriceTax = parseFloat(item.price) - parseFloat(item.price) / (1 + parseFloat(item.priceTaxRate) / 100);
      totalShippingPrice = isExcludeShippingAmount
        ? 0
        : baseHelper.formatPrice(item.shippingAmount + (item.shippingTax || 0));
      totalVendorPayout = baseHelper.formatPrice(
        parseFloat(item.commissionVendor) + parseFloat(totalShippingPrice) /* - parseFloat(totalCommissionPrice) */
      );
      // const { term: shippingTerm = "" } = item.shipping;
      const shippingAmount = baseHelper.formatPrice(item.shippingAmount + (item.shippingTax || 0));
      // shippingAmount = shippingTerm === constant.VENDOR_MANAGE
      //     ? (item.shipping && item.shipping.amount) || 0
      //     : baseHelper.formatPrice(item.shippingAmount + (item.shippingTax || 0));
      if (isWholesalePriceVisible) {
        tempOrderData.push(
          `${moneyFormat} ${baseHelper.formatPrice((isVendor ? item.vendorWholesalePrice : item.wholesalePrice) || 0)}`
        );
      }

      const validFulfillmentType =
        fulfillmentType && (!isVendor || ![constant.CONSIGNMENT, constant.PICKUP].includes(fulfillmentType));
      const isConsignment = fulfillmentType && isVendor && [constant.CONSIGNMENT].includes(fulfillmentType);

      const roleBaseData = isVendor
        ? [
            `${item.moneyFormat} ${baseHelper.formatPrice(totalAllocatedDiscount || 0)}`,
            `${item.moneyFormat} ${baseHelper.formatPrice(shippingAmount || 0)}`,
            `${item.moneyFormat} ${baseHelper.formatPrice(totalVendorPayout || 0)}`,
            `${item.moneyFormat} ${baseHelper.formatPrice(item.totalTax) || 0}`,
            `${moneyFormat} ${item.markUpPrice}`,
            `${moneyFormat} ${baseHelper.formatPrice(item.comparePrice) || 0}`,
            `${item.moneyFormat} ${baseHelper.formatPrice(customerPrice) || 0}`,
          ]
        : [`${(item && item.vendor) || ""}`, `${moneyFormat} ${item.markUpPrice}`];

      newOrderArray[idx + 1] = [
        `${item.shopifyOrderId}`,
        `${item.shopifyLineItemId}`,
        item.name,
        item.sku,
        item.grams,
        `#${item.orderNumber}`,
        item.status,
        item.fulfillmentStatus,
        item.quantity,
        // ...tempOrderData,
        `${item.moneyFormat} ${baseHelper.formatPrice(price) || 0}`,
        baseHelper.formatDate(item.createdAt),
        item.trackingNumber,
        `${
          (validFulfillmentType &&
            shippingData &&
            `${shippingData.first_name || ""} ${shippingData.last_name || ""}`) ||
          ""
        }`,
        `${
          (validFulfillmentType && shippingData && `${shippingData.address1 || ""} ${shippingData.address2 || ""}`) ||
          (isConsignment && `${sellerAddress || ""}`) ||
          ""
        }`,
        `${
          (validFulfillmentType && shippingData && `${shippingData.city || ""}`) ||
          (isConsignment && `${sellerCity || ""}`) ||
          ""
        }`,
        `${(validFulfillmentType && shippingData && `${shippingData.province || ""}`) || ""}`,
        `${
          (validFulfillmentType && shippingData && `${shippingData.country || ""}`) ||
          (isConsignment && `${sellerCountry || ""}`) ||
          ""
        }`,
        `${
          (validFulfillmentType && shippingData && `${shippingData.zip || ""}`) ||
          (isConsignment && `${sellerPostalCode || ""}`) ||
          ""
        }`,
        ...roleBaseData,
      ].filter((value, index) => {
        return removeIndex.indexOf(index) === -1;
      });
    });
    return newOrderArray;
  };

  /**
   * @desc To return orders csv data
   * @param orderLines array of orderlines
   *
   * @return csv data
   *
   */
  orderFulfillCSVData = (orderLines = [], cms) => {
    const orderArray = [
      cms("section.exportOrder.label.ordernumber"),
      cms("section.exportOrder.label.product"),
      cms("section.exportOrder.label.orderid"),
      cms("section.exportOrder.label.lineitemid"),
      cms("section.orderList.label.trackingNumber"),
      cms("section.orderList.label.trackingCompany"),
    ];

    const newOrderArray = [];
    newOrderArray[0] = orderArray;

    orderLines.forEach((item, idx) => {
      newOrderArray[idx + 1] = [
        `#${item.orderNumber}`,
        item.name,
        `${item.orderId}`,
        `${item._id}`,
        item.trackingNumber,
        item.trackingCompany,
      ];
    });
    return newOrderArray;
  };
}

export default new OrderHelper();
