/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect } from "react";
import { DisplayText, Layout, Modal, PageActions, Stack, TextContainer, Thumbnail } from "@shopify/polaris";

import { useQuery, useMutation } from "react-apollo";
import _ from "lodash";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner, Banner } from "lib/components";
import { baseHelper, imageHelper, errorHelper } from "lib/helpers";

import GET_PRODUCT_AND_SELLER_PRODUCT from "app/productOld/apollo/queries/reviewProduct";
import { GET_PRODUCT_SETTING } from "app/productOld/apollo/queries";
import { GET_CATALOG } from "app/product/apollo/queries";
import { APPROVE_ALL_CHANGES, APPROVE_SELLER_CHANGES } from "app/productOld/apollo/mutations/reviewProduct";

import Variant from "./subFeature/variant";

import content from "./reviewContent";
import ReviewProduct from "./subFeature/reviewProduct";

const noImageUrl = "https://placehold.co/400/grey/white?text=No+Image";
const noAttachmentUrl = constant.NOATTACHMENTSNAP;
const filePlaceHolder = constant.FILEPLACEHOLDER;
const allowedImageTypes = ["bmp", "gif", "png", "jpg", "jpeg", "svg%2Bxml", "vnd", "webp"];
const checkAttachment = "Attachment";

const OperatorProductReview = () => {
  const { match, history, currentUser, cms } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;

  const { labels } = content;

  const [diff, setDiff] = useState({});
  const [keys, setKeys] = useState([]);
  const [vendorProducts, setVendorProducts] = useState({});
  const [sellerProducts, setSellerProducts] = useState({});
  const [isVariant, setIsVariant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [notApproveLoading, setNotApproveLoading] = useState(false);
  const [unselectedKeys, setUnselectedKeys] = useState([]);
  const [isVariantIdAvailable, setIsVariantIdAvailable] = useState(false);
  const [isHideTagAndType, setIsHideTagAndType] = useState(false);
  const [isHideTag, setIsHideTag] = useState(true);
  const [isHideType, setIsHideType] = useState(true);
  const [isHideCollection, setIsHideCollection] = useState(true);
  const [isBypassTDI, setIsBypassTDI] = useState(false);
  const [catalog, setCatalog] = useState([]);

  const [active, setActive] = useState(false);
  let isPrice = false;

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { data, loading, error } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, { variables: { input: { _id: id } } });
  const { data: productSettingData, loading: productSettingLoading, error: productSettingError } = useQuery(
    GET_PRODUCT_SETTING
  );

  const [approveAllChanges] = useMutation(APPROVE_ALL_CHANGES);
  const [approveSeller] = useMutation(APPROVE_SELLER_CHANGES);

  useEffect(() => {
    const difference = (object, base) =>
      _.transform(object, (result, value, key) => {
        if (value !== base[key]) {
          // eslint-disable-next-line no-param-reassign
          result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
        }
      });
    if (
      !error &&
      !loading &&
      !productSettingLoading &&
      !productSettingError &&
      data &&
      productSettingData &&
      isLoading
    ) {
      setIsLoading(false);
      const resData = baseHelper.getResponseData(data, constant.gql.GET_PRODUCT_SELLER_PRODUCT);
      const settingData = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING);
      const {
        isHideTag: isHideTagSettingData = true,
        isHideType: isHideTypeSettingData = true,
        isHideTagAndType: isHideTagAndTypeSettingData = false,
        isCatalogHidden: isHideCollectionSettingData,
      } = settingData || {};
      setIsHideTagAndType(!!isHideTagAndTypeSettingData);
      setIsHideTag(!!isHideTagSettingData);
      setIsHideType(!!isHideTypeSettingData);
      setIsHideCollection(!!isHideCollectionSettingData);
      const vendorProduct = { ...resData.product };
      const sellerProduct = { ...resData.sellerProduct };

      sellerProduct.description = (sellerProduct.description || "").replaceAll("<br />", "<br>");
      vendorProduct.description = (vendorProduct.description || "").replaceAll("<br />", "<br>");

      const { length: productLength = {}, width: productWidth = {}, height: productHeight = {} } =
        (vendorProduct && vendorProduct.measurement) || {};
      const { wholesalePrice } = (vendorProduct && vendorProduct.customInfo) || {};

      vendorProduct.length = (productLength && productLength.value) || 0;
      vendorProduct.width = (productWidth && productWidth.value) || 0;
      vendorProduct.height = (productHeight && productHeight.value) || 0;
      vendorProduct.wholesalePrice = wholesalePrice || 0;

      const { length: sellerProductLength = {}, width: sellerProductWidth = {}, height: sellerProductHeight = {} } =
        (sellerProduct && sellerProduct.measurement) || {};
      const { wholesalePrice: sWholesalePrice } = (sellerProduct && sellerProduct.customInfo) || {};

      sellerProduct.length = (sellerProductLength && sellerProductLength.value) || 0;
      sellerProduct.width = (sellerProductWidth && sellerProductWidth.value) || 0;
      sellerProduct.height = (sellerProductHeight && sellerProductHeight.value) || 0;
      sellerProduct.wholesalePrice = sWholesalePrice || 0;

      const key = _.keys(vendorProduct);

      const sellerImages = (sellerProduct && sellerProduct.images && sellerProduct.images.length) || 0;
      const vendorImages = (vendorProduct && vendorProduct.images && vendorProduct.images.length) || 0;

      const sellerAttachments = (sellerProduct && sellerProduct.attachments && sellerProduct.attachments.length) || 0;
      const vendorAttachments = (vendorProduct && vendorProduct.attachments && vendorProduct.attachments.length) || 0;

      const sellerVariants = (sellerProduct && sellerProduct.variants && sellerProduct.variants.length) || 0;
      const vendorVariants = (vendorProduct && vendorProduct.variants && vendorProduct.variants.length) || 0;

      const sellerCatalog =
        (sellerProduct && sellerProduct.shopifyCatalogId && sellerProduct.shopifyCatalogId.length) || 0;
      const vendorCatalog =
        (vendorProduct && vendorProduct.shopifyCatalogId && vendorProduct.shopifyCatalogId.length) || 0;

      const { images = [] } =
        sellerImages > vendorImages
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      const { attachments = [] } =
        sellerAttachments > vendorAttachments
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      const { shopifyCatalogId = [] } =
        sellerCatalog > vendorCatalog
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      const diffValue =
        sellerVariants > vendorVariants
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      delete diffValue.images;
      diffValue.images = [...(images || [])];
      delete diffValue.attachments;
      diffValue.attachments = [...(attachments || [])];
      delete diffValue.shopifyCatalogId;
      diffValue.shopifyCatalogId = [...(shopifyCatalogId || [])];

      const variant = !!(sellerVariants || vendorVariants);

      setDiff(diffValue);
      setKeys(key);
      setVendorProducts(vendorProduct);
      setSellerProducts(sellerProduct);
      setIsVariant(variant);
      setIsBypassTDI(["63c5618713aacc00199b4e61", "64a99dec15021500138216de"].includes(currentUser._id)); // for maison
    }
  }, [data, error, isLoading, keys, loading, productSettingLoading, productSettingError, productSettingData]);

  const { data: getCatalogData, error: getCatalogError } = useQuery(GET_CATALOG);
  const responseCatalogData = baseHelper.getResponseData(getCatalogData, constant.gql.GET_CATALOG);
  const responseCatalogError = baseHelper.getResponseError(getCatalogData, constant.gql.GET_CATALOG);

  useEffect(() => {
    if (responseCatalogData) {
      setCatalog(responseCatalogData.catalog || []);
    }
    if (responseCatalogError) {
      setBanner({ isOpen: true, title: getCatalogError, status: constant.CRITICAL });
    }
  }, [getCatalogData, getCatalogError, responseCatalogData, responseCatalogError]);

  const downloadFile = (file) => {
    window.open(file, "_blank");
  };

  const getDataInHTMLForm = (value) => ({ __html: value });

  const handleChange = (_id, value) => {
    if (value) {
      setActive(true);
    } else {
      // eslint-disable-next-line no-use-before-define
      approveAll(_id, false);
    }
  };

  const getData = (value) => (_.isBoolean(value) && (value ? labels.yes : labels.no)) || value;
  const difference = (object, base) =>
    _.transform(object, (result, value, key) => {
      if (value !== base[key]) {
        // eslint-disable-next-line
        result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
      }
    });

  let variantDiffs = [];
  const variantIdAvailable = {};
  const getTableRows = (value) => {
    const tableRows = [];
    const label = [];
    const vendorRow = [];
    const sellerRow = [];
    const vendorCatalog = [];
    const sellerCatalog = [];

    if (!loading && !error && data) {
      Object.keys(value).map((key) => {
        const allowedKeys = isBypassTDI ? constant.ALLOWED_KEYS.filter(item => !["title", "description", "images"].includes(item)) : constant.ALLOWED_KEYS;

        if (isVariant && !allowedKeys.includes(key)) {
          return null;
        }

        if (isBypassTDI && ["title", "description", "images"].includes(key)) {
          return null;
        }

        if (!(vendorProducts[key] || sellerProducts[key])) {
          return null;
        }
        if (!isHideTag && key === constant.PRODUCT_TAGS) {
          return null;
        }
        if (!isHideType && key === constant.PRODUCT_TYPE) {
          return null;
        }
        if (isHideCollection && key === constant.SHOPIFY_CATALOG_ID) {
          return null;
        }
        if (key === constant.CUSTOM_METAFIELD && !(diff && diff.customMetafields && diff.customMetafields.length)) {
          return null;
        }

        const isVariantPrice = diff && diff.variants.find((item) => item.price);
        if (key === "price" || isVariantPrice) {
          isPrice = true;
        }

        if (
          _.includes(keys, key) &&
          labels[key] &&
          key !== constant.IMAGES &&
          key !== constant.VARIANTS &&
          key !== constant.ATTACHMENTS
        ) {
          const isKeyDescription = key === constant.DESCRIPTION;
          const isKeyTags = key === constant.TAGS;
          const isKeyCollection = key === constant.SHOPIFY_CATALOG_ID;
          if (isKeyCollection) {
            vendorProducts[key] &&
              Array.isArray(vendorProducts[key]) &&
              vendorProducts[key].forEach((row) => {
                const vendorCollection = catalog.find((item) => item.storeCatalogId === row);
                const { title = "" } = vendorCollection || {};
                vendorCatalog.push(title);
              });
            sellerProducts[key] &&
              Array.isArray(sellerProducts[key]) &&
              sellerProducts[key].forEach((row) => {
                const sellerCollection = catalog.find((item) => item.storeCatalogId === row);
                const { title = "" } = sellerCollection || {};
                sellerCatalog.push(title);
              });
          }

          // const vendorSort = baseHelper.sort(vendorProducts[key], constant.NAME);
          // const sellerSort = baseHelper.sort(sellerProducts[key], constant.NAME);

          // eslint-disable-next-line no-nested-ternary
          const vendorData = isKeyTags
            ? baseHelper.sort(vendorProducts[key]).join(", ")
            : isKeyCollection
            ? baseHelper.sort(vendorCatalog).join(", ")
            : getData(vendorProducts[key]);
          // eslint-disable-next-line no-nested-ternary
          const sellerData = isKeyTags
            ? baseHelper.sort(sellerProducts[key]).join(", ")
            : isKeyCollection
            ? baseHelper.sort(sellerCatalog).join(", ")
            : getData(sellerProducts[key]);
          if ((isKeyTags || isKeyCollection) && (!(vendorData || sellerData) || vendorData === sellerData)) {
            return null;
          }
          let vendorProductData =
            isKeyDescription && vendorData ? (
              // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={getDataInHTMLForm(vendorData)} />
            ) : (
              vendorData || []
            );
          let sellerProductData =
            isKeyDescription && sellerData ? (
              // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={getDataInHTMLForm(sellerData)} />
            ) : (
              sellerData || []
            );

          if (key === constant.CUSTOM_METAFIELD) {
            vendorProductData = [...new Map(vendorProductData.map((item) => [item.key, item])).values()];

            sellerProductData = [...new Map(sellerProductData.map((item) => [item.key, item])).values()];
            const isSellerUpdate = !!(vendorProductData.length < sellerProductData.length);
            const isVendorUpdate = !!(vendorProductData.length > sellerProductData.length);
            const isEqual = !!(vendorProductData.length === sellerProductData.length);
            let vendorCount = 0;
            if (isVendorUpdate) {
              vendorProductData.map((item) => {
                const compareData = sellerProductData.find((sellerItem) => sellerItem.key === item.key);
                if (compareData) {
                  if (JSON.stringify(compareData.value) === JSON.stringify(item.value)) {
                    vendorCount += 1;
                  }
                }
              });
            }

            let sellerCount = 0;
            if (isSellerUpdate || isEqual) {
              sellerProductData.map((item) => {
                const compareData = vendorProductData.find((vendorItem) => vendorItem.key === item.key);
                if (compareData) {
                  if (JSON.stringify(compareData.value) === JSON.stringify(item.value)) {
                    sellerCount += 1;
                  }
                }
              });
            }
            let isSameSellerData = false;
            if ((isSellerUpdate || isEqual) && sellerCount === vendorProductData.length) {
              isSameSellerData = true;
            }
            let isSameVendorData = false;
            if (isSellerUpdate && vendorCount === sellerProductData.length) {
              isSameVendorData = true;
            }

            if (isSameSellerData || isSameVendorData) {
              return null;
            }
          }

          tableRows.push([labels[key], vendorProductData, sellerProductData, key]);
          label.push(labels[key]);
          vendorRow.push(vendorProductData);
          sellerRow.push(sellerProductData);
        }

        if (key === constant.IMAGES && diff.images && diff.images.length) {
          const vImages = [];
          const sImages = [];
          diff.images.map((image, idx) => {
            const vendorImage = (vendorProducts.images[idx] && vendorProducts.images[idx].imageUrl) || noImageUrl;
            const sellerImage = (sellerProducts.images[idx] && sellerProducts.images[idx].imageUrl) || noImageUrl;
            if (vendorImage !== sellerImage) {
              vImages.push([
                <Thumbnail
                  source={imageHelper.resize({ url: vendorImage, type: constant.imageTypes.THUMBNAIL })}
                  alt={cms("label.vendorImage")}
                />,
              ]);
              sImages.push([
                <Thumbnail
                  source={imageHelper.resize({ url: sellerImage, type: constant.imageTypes.THUMBNAIL })}
                  alt={cms("label.sellerImage")}
                />,
              ]);
            }
            return null;
          });
          if (vImages.length && sImages.length) {
            tableRows.push([labels[key], <Stack>{vImages}</Stack>, <Stack>{sImages}</Stack>, key]);
            label.push(labels[key]);
            vendorRow.push(<Stack>{vImages}</Stack>);
            sellerRow.push(<Stack>{sImages}</Stack>);
          }
        }
        if (key === constant.ATTACHMENTS && diff.attachments && diff.attachments.length) {
          const vAttachments = [];
          const sAttachments = [];
          diff.attachments.map((attachment, idx) => {
            const { attachments: vendorProductAttachments = [] } = vendorProducts || {};
            const vendorFileUrl =
              (vendorProductAttachments && vendorProductAttachments[idx] && vendorProductAttachments[idx].fileURL) ||
              "";
            const { attachments: sellerProductAttachments = [] } = sellerProducts || {};
            const sellerFileUrl =
              (sellerProductAttachments && sellerProductAttachments[idx] && sellerProductAttachments[idx].fileURL) ||
              "";
            const vendorFileExtension = vendorFileUrl && /[^.]*$/.exec(vendorFileUrl)[0];
            const sellerFileExtension = sellerFileUrl && /[^.]*$/.exec(sellerFileUrl)[0];

            const vendorAttachment =
              (vendorProductAttachments && vendorProductAttachments[idx] && vendorProductAttachments[idx].fileURL) ||
              noAttachmentUrl;
            const sellerAttachment =
              (sellerProductAttachments && sellerProductAttachments[idx] && sellerProductAttachments[idx].fileURL) ||
              noAttachmentUrl;

            if (vendorAttachment !== sellerAttachment) {
              const vendorPlaceHolder = vendorAttachment.includes(checkAttachment) ? vendorAttachment : filePlaceHolder;
              const sellerPlaceHolder = sellerAttachment.includes(checkAttachment) ? sellerAttachment : filePlaceHolder;
              const sellerSourceFile = allowedImageTypes.includes(sellerFileExtension)
                ? sellerFileUrl
                : sellerPlaceHolder;
              const vendorSourceFile = allowedImageTypes.includes(vendorFileExtension)
                ? vendorFileUrl
                : vendorPlaceHolder;

              vAttachments.push([
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div className="container" onClick={() => downloadFile(vendorSourceFile)}>
                  <div className="attachment">
                    <Thumbnail alt={cms("label.vendorAttachment")} size="large" source={vendorSourceFile} />
                    <a className="download-icon">
                      <i className="fa fa-download" />
                    </a>
                  </div>
                </div>,
              ]);
              sAttachments.push([
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                <div onClick={() => downloadFile(sellerSourceFile)}>
                  <div className="attachment">
                    <Thumbnail alt={cms("label.vendorAttachment")} size="large" source={sellerSourceFile} />
                    <a className="download-icon">
                      <i className="fa fa-download" />
                    </a>
                  </div>
                </div>,
              ]);
            }
            return null;
          });
          if (vAttachments.length && sAttachments.length) {
            tableRows.push([labels[key], <Stack>{vAttachments}</Stack>, <Stack>{sAttachments}</Stack>, key]);
            label.push(labels[key]);
            vendorRow.push(<Stack>{vAttachments}</Stack>);
            sellerRow.push(<Stack>{sAttachments}</Stack>);
          }
        }

        const sellerVariantData =
          sellerProducts && sellerProducts.variants && sellerProducts.variants.length ? sellerProducts.variants : null;
        const vendorVariantData =
          vendorProducts && vendorProducts.variants && vendorProducts.variants.length ? vendorProducts.variants : null;
        const sellerVariantLength = sellerProducts && sellerProducts.variants && sellerProducts.variants.length;
        const vendorVariantLength = vendorProducts && vendorProducts.variants && vendorProducts.variants.length;

        if (isVariant) {
          const isSellerHasMoreVariants = sellerVariantLength > vendorVariantLength;
          const sellerVariants = [...(sellerVariantData || [])];
          const vendorVariants = [...(vendorVariantData || [])];
          const compareFromVariants = isSellerHasMoreVariants ? [...sellerVariants] : [...vendorVariants];
          const compareWithVariants = isSellerHasMoreVariants ? [...vendorVariants] : [...sellerVariants];
          const isVariantId = sellerVariants.every((item) => !!item.variantId);
          const isEqualVariants = sellerVariantLength === vendorVariantLength;

          variantDiffs = compareFromVariants.map((itemVariant) => {
            const variantId = baseHelper.mongoIdAsString(
              isSellerHasMoreVariants ? itemVariant.variantId : itemVariant._id
            );

            const { measurement = {}, customInfo = {} } = itemVariant;
            const { length = {}, width = {}, height = {} } = measurement || {};
            const { wholesalePrice } = customInfo || {};
            itemVariant.length = (length && length.value) || 0;
            itemVariant.width = (width && width.value) || 0;
            itemVariant.height = (height && height.value) || 0;
            itemVariant.wholesalePrice = wholesalePrice || 0;

            const variantChanges = {};
            let variantWith = false;
            if (isVariantId && variantId) {
              variantChanges.variantId = variantId;
              variantWith = compareWithVariants.find((item) => {
                const variantIdWith = baseHelper.mongoIdAsString(item[isSellerHasMoreVariants ? "_id" : "variantId"]);
                return variantIdWith === variantId;
              });
              if (variantWith) {
                const { measurement: withIdMeasurement = {}, customInfo: withIdCustomInfo = {} } = variantWith;
                const { length: withIdLength = {}, width: withIdWidth = {}, height: withIdHeight = {} } =
                  withIdMeasurement || {};
                const { wholesalePrice: withIdWholesalePrice } = withIdCustomInfo || {};

                variantWith.length = (withIdLength && withIdLength.value) || 0;
                variantWith.width = (withIdWidth && withIdWidth.value) || 0;
                variantWith.height = (withIdHeight && withIdHeight.value) || 0;
                variantWith.wholesalePrice = withIdWholesalePrice || 0;
              }
            } else {
              const { position } = itemVariant;
              variantChanges.position = position;
              variantWith = compareWithVariants.find((item) => item.position === position);
              if (variantWith) {
                const { measurement: withoutIdMeasurement = {}, customInfo: withoutIdCustomInfo = {} } = variantWith;
                const { length: withoutIdLength = {}, width: withoutIdWidth = {}, height: withoutIdHeight = {} } =
                  withoutIdMeasurement || {};
                const { wholesalePrice: withoutIdWholesalePrice } = withoutIdCustomInfo || {};

                variantWith.length = (withoutIdLength && withoutIdLength.value) || 0;
                variantWith.width = (withoutIdWidth && withoutIdWidth.value) || 0;
                variantWith.height = (withoutIdHeight && withoutIdHeight.value) || 0;
                variantWith.wholesalePrice = withoutIdWholesalePrice || 0;
              }
            }

            if (!variantWith) {
              variantChanges[isSellerHasMoreVariants ? "isDeleted" : "isAdded"] = true;
            } else {
              variantChanges.variantDiff = difference(itemVariant, variantWith);
              variantChanges.isUpdated = true;
              variantChanges.position = isSellerHasMoreVariants ? itemVariant.position : variantWith.position;
            }

            variantChanges.sellerVariant = isSellerHasMoreVariants
              ? { ...itemVariant }
              : (variantWith && { ...variantWith }) || null;
            variantChanges.vendorVariant = isSellerHasMoreVariants
              ? (variantWith && { ...variantWith }) || null
              : { ...itemVariant };
            variantChanges.isVariantId = isVariantId;
            variantIdAvailable.isId = isVariantId;
            if (isEqualVariants && !variantWith && isVariantId && variantId) {
              const avalaibleVariantIds = compareFromVariants.map((item) => item.variantId);
              const variantAdded = vendorVariants.find(
                (item) => !avalaibleVariantIds.includes(baseHelper.mongoIdAsString(item._id))
              );
              if (variantAdded) {
                const extraVariantChange = { ...variantChanges };
                extraVariantChange.sellerVariant = null;
                extraVariantChange.vendorVariant = variantAdded && { ...variantAdded };
                extraVariantChange.isAdded = true;
                delete extraVariantChange.isDeleted;
                delete extraVariantChange.isUpdated;
                return extraVariantChange;
              }
            }

            return variantChanges;
          });
        }
        return null;
      });
    }

    if (variantIdAvailable.isId !== isVariantIdAvailable) {
      setIsVariantIdAvailable(variantIdAvailable.isId);
    }
    return tableRows;
  };

  const setURL = () => {
    setTimeout(() => {
      history.push("/products");
    }, 2500);
  };

  const onToggleSelect = (key) => {
    const allUnselectedKeys = unselectedKeys;
    const keyIndex = allUnselectedKeys.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      allUnselectedKeys.splice(keyIndex, 1);
    } else {
      allUnselectedKeys.push(key);
    }
    setUnselectedKeys(allUnselectedKeys);
  };

  const approveAll = (_id, isToPublish = true) => {
    setPublishLoading(!isToPublish);
    setApproveLoading(isToPublish);
    setNotApproveLoading(false);

    approveAllChanges({ variables: { input: { id: _id, isToPublish, unselectedKeys } } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, constant.gql.APPROVE_ALL_CHANGES);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = constant.SUCCESS;
        bannerData.title = cms("message.success.approved");
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = constant.CRITICAL;
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title });
        setPublishLoading(false);
        setApproveLoading(false);
        if (bannerData.url) {
          setURL();
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
    setActive(false);
  };
  const approveSellerChanges = (_id) => {
    setNotApproveLoading(true);
    setPublishLoading(false);
    setApproveLoading(false);
    approveSeller({ variables: { input: { id: _id } } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, constant.gql.APPROVE_SELLER_CHANGES);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = constant.SUCCESS;
        bannerData.title = cms("message.error.declined");
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = constant.CRITICAL;
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title });
        setNotApproveLoading(false);
        if (bannerData.url) {
          setURL();
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(exception) });
      });
  };

  const rows = getTableRows(diff);

  const { _id } = vendorProducts;

  const rendorPopup = () => {
    return (
      <>
        <Modal
          open={active}
          onClose={() => {
            setActive(false);
          }}
          title={cms("modal.title")}
          primaryAction={{
            content: cms("modal.button.confirm"),
            onAction: () => approveAll(_id, false),
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              onAction: () => setActive(false),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>{cms("modal.message")}</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </>
    );
  };

  if (loading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <Layout.Section>
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ isOpen: false })}
        />
      </Layout.Section>
      <Layout.Section>
        {sellerProducts && sellerProducts.title && <DisplayText size="large">{sellerProducts.title}</DisplayText>}
      </Layout.Section>
      <Layout.Section>
        {rows.map((productData) => {
          return (
            <ReviewProduct
              store={currentUser && currentUser.shop}
              vendor={vendorProducts && vendorProducts.vendor}
              onToggleSelect={onToggleSelect}
              date={baseHelper.formatDate(vendorProducts.updatedAt)}
              cms={cms}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...productData}
            />
          );
        })}
        {variantDiffs.map((variantsData) => {
          return (
            <Variant
              store={currentUser && currentUser.shop}
              vendor={vendorProducts && vendorProducts.vendor}
              onToggleSelect={onToggleSelect}
              date={baseHelper.formatDate(vendorProducts.updatedAt)}
              isVariantIdAvailable={isVariantIdAvailable}
              cms={cms}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...variantsData}
            />
          );
        })}
      </Layout.Section>
      {rendorPopup()}
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: cms("label.acceptAndPublish"),
            onAction: () => approveAll(_id),
            loading: approveLoading,
            disabled: notApproveLoading || publishLoading,
          }}
          secondaryActions={[
            {
              content: cms("label.acceptAndNotPublish"),
              onAction: () => handleChange(_id, isPrice),
              loading: publishLoading,
              disabled: notApproveLoading || approveLoading,
              destructive: true,
              size: constant.SLIM,
            },
            {
              content: cms("label.notAccept"),
              onAction: () => approveSellerChanges(_id, false),
              loading: notApproveLoading,
              disabled: approveLoading || publishLoading,
              size: constant.SLIM,
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductReview), {
  feature: constant.REVIEW_PRODUCT,
});
