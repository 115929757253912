import { gql } from "apollo-boost";

export const GET_SHIPPING_OFFER = gql`
  query {
    getShippingOffers {
      status
      data {
        shippingOffers {
          _id
          parentId
          condition
          createdBy
          description
          destinationCode
          isCumulative
          orderValue {
            criteria
            value
          }
          price
          priceType
          productId
          productPrice {
            criteria
            value
          }
          productQuantity {
            criteria
            value
          }
          shippingBandId
          userId
          createdAt
          updatedAt
        }
      }
      error
    }
  }
`;

export const GET_SHIPPING_REGION = gql`
  query getRegion {
    getRegion {
      data {
        options {
          value
          label
        }
        title
      }
      status
      error
    }
  }
`;

export const GET_SHIPPING_PRODUCT = gql`
  query getProducts {
    getProducts {
      data {
        value
        label
      }
      status
      error
    }
  }
`;

export const GET_SHIPPING_LAKE_PRODUCT = gql`
  query getLakeProducts {
    getLakeProducts {
      data {
        value
        label
      }
      status
      error
    }
  }
`;
